import { DialogController } from 'aurelia-dialog';
import { bindable, inject } from 'aurelia-framework';

@inject(DialogController)
export class MDCDelete {
    @bindable items;

    constructor(private controller: DialogController) { }

    activate(items) {
        this.items = items;
    }
}
