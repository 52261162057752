import { AlertService } from '../../services/util/Alert';
import { ensureArray } from '../../util/array/ensureArray';
import { autoinject, bindable } from 'aurelia-framework';
import { APIFile } from '@wsb_dev/datafi-shared/lib/types/File';
import { getFileIcon, IMAGE } from '@wsb_dev/datafi-shared/lib/types/FileTypes';
import { subscribe } from '../../util/decorators/subscribe';
import { DatafiProAPI } from '../../services/api/DatafiProAPI';
import { createCustomEvent } from '../../util/events/createCustomEvent';

export interface IUploadEventDetail {
    created: APIFile[];
    removed: APIFile[];
}

@subscribe({
    events: [
        {
            eventEmitter: 'api.files',
            event: 'uploadprogress',
            fn: 'onUploadProgress',
        },
    ],
})
@autoinject
export class MdcUploadField {
    @bindable pending: boolean;
    @bindable isDragover: boolean;
    @bindable single: boolean;

    @bindable progress: number;
    /**
     * The array of files uploaded.
     */
    @bindable files: APIFile[] = [];

    /**
     * Optional - provide a function to rename files
     * @param {File} f the file to get an ID for
     * @returns the id for the file
     */
    @bindable getFileName: (f: File) => string;

    constructor(
        private api: DatafiProAPI,
        private element: Element,
        private alerts: AlertService,
    ) {
    }

    onUploadProgress = (event: ProgressEvent): void => {
        this.progress = event.loaded / event.total;
    };

    updateDragover(over: boolean): void {
        this.isDragover = over;
    }

    upload(files: FileList): Promise<void> {
        this.pending = true;
        this.isDragover = false;

        const uploads = Array.from(files).map((f) => {
            return {
                id: this.getFileName ? this.getFileName(f) : undefined,
                file: f,
            };
        });

        let removals: APIFile[] = [];

        // if we 're in single mode - we need to remove existing files first
        const promise = this.single
            ? Promise.all(
                this.files.map((file) => this.api.files.remove(file.id)),
            ).then(() => {
                removals = [...this.files];
                this.files = [];
            })
            : Promise.resolve();

        return promise.then(() => {
            return this.api.files
                .create(uploads)
                .then((uploads: APIFile[]) => {
                    uploads = ensureArray(uploads);
                    uploads.forEach((f, index) => {
                        f.name = files[index].name;
                        f.image = this.isImage(f);
                        f.icon = getFileIcon(f.id);
                    });

                    this.files = this.files
                        ? this.files.concat(uploads)
                        : uploads;

                    this.alerts.create({
                        label: `File${uploads.length > 1 ? 's' : ''} uploaded`,
                        dismissable: true,
                    });

                    this.element.dispatchEvent(
                        createCustomEvent(
                            'created',
                            {
                                created: uploads,
                                removed: removals,
                            } as IUploadEventDetail,
                            true,
                        ),
                    );

                    if (this.single) {
                        return;
                    }
                })
                .catch((e) => {
                    this.alerts.create({
                        label: e.message,
                        level: 'error',
                        dismissable: true,
                    });
                })
                .finally(() => {
                    this.pending = false;
                    this.progress = 0;
                });
        });
    }

    isImage(file: APIFile): boolean {
        return IMAGE.test(file.id);
    }

    delete(file: APIFile): Promise<APIFile> {
        const index = this.files.indexOf(file);
        return this.api.files.remove(file.id).then((result) => {
            this.alerts.create({
                label: 'File removed',
                dismissable: true,
            });

            this.element.dispatchEvent(
                createCustomEvent(
                    'removed',
                    {
                        created: [],
                        removed: [file],
                    } as IUploadEventDetail,
                    true,
                ),
            );

            return result;
        });
    }
}
