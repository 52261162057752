import { Field } from '@wsb_dev/datafi-shared/lib/types';
import { LogManager, autoinject, bindable, bindingMode } from 'aurelia-framework';
import { getValueAsBoolean } from '@wsb_dev/datafi-shared/lib/util/surveys/getFields';
import dayjs from 'dayjs';
import { ASTAny, evaluate } from '@wsb_dev/dfp-xform-parser/lib/evaluate';
import { createCustomEvent } from '../../util/events/createCustomEvent';

const log = LogManager.getLogger('dfp:form');

export interface ConstraintError {
    field: string;
    message: string;
}
export interface FormEventData {
    object: Record<string, any>;
    context: Record<string, any>;
    schema: Field[];
    constraintErrors: ConstraintError[];
    isValid: boolean;
}

@autoinject
export class MdcDynamicForm {
    @bindable schema: Field[];
    @bindable({ defaultBindingMode: bindingMode.twoWay }) object: Record<string, any>;
    @bindable context: Record<string, any>;
    @bindable constraintErrors: Record<string, string>;

    constructor(
        private el: Element,
    ) { }

    bind() {
        if (!this.object) {
            this.object = {};
        }
        if (!this.constraintErrors) {
            this.constraintErrors = {};
        }
        this.updateContext();
        this.schema.forEach((item) => {
            if (!this.object[item.name]) {

                if (item.default) {
                    const res = typeof item.default === 'string' ?
                        // parse calculation
                        this.evaluateWithContext(item.name, item.defaultAst) :
                        item.default;
                    this.object[item.name] = res;
                }

                if ((item.type === 'checkbox' || item.type === 'array')) {
                    this.ensureArrayExists(item.name);
                } else if (item.type === 'object') {

                    this.object[item.name] = {};
                }
            }

            if (item.name === 'metadata') {
                this.object.metadata.__expanded = item.name === 'metadata';
            }
        });

        // run calculations after defaults are set
        this.schema.forEach((item) => {
            if (item.calculation) {
                this.updateValue(item);
            }

            if (item.relevant) {
                this.updateVisibility(item);
            }
        });
    }

    updateContext() {
        this.context = {
            ...this.context,
            ...this.object,
        };
    }

    updateDependencies(field: Field) {
        this.updateContext();

        if (field.constraint) {
            const res = this.evaluateWithContext(field.name, field.constraintAst);
            if (!res) {
                this.constraintErrors[field.name] = field.constraintError || `Invalid value for ${field.label} (${field.constraint.replace('.', field.name)})`;
            } else {
                this.constraintErrors[field.name] = '';
            }
        }

        const dependancies = this.schema.filter((f) => f.dependencies?.includes(field.name));
        dependancies.forEach((dependant) => {
            if (dependant.calculation) {
                this.updateValue(dependant);
            }

            if (dependant.relevant) {
                this.updateVisibility(dependant);
            }
        });
    }

    updateValue(item: Field): void {

        // if there's a once value, and a value is already calculated,
        // do not re-calculate it
        if (item.calculation?.includes('once') && this.object[item.name]) {
            return;
        }

        let res = this.evaluateWithContext(item.name, item.calculationAst);

        // handle date and time conversion
        if (item.type === 'date') {
            res = dayjs(res).format('YYYY-MM-DD');
        } else if (item.type === 'time') {
            res = dayjs(res).format('HH:mm');
        }

        if (res && res !== this.object[item.name]) {
            log.debug(`dependancy changed, updating ${item.name} to ${res}`);
            this.object[item.name] = res;
            this.updateDependencies(item);
        }
    }

    evaluateWithContext(fieldName: string, ast: ASTAny) {
        log.debug(`evaluating ${fieldName}`);
        return evaluate(this.context, ast);
    }

    updateVisibility(item: Field): void {
        const res = this.evaluateWithContext(item.name, item.relevantAst);
        const bool = getValueAsBoolean(res);
        log.debug(`calculated relevant visibility ${item.name} to ${bool}`);
        if (!item.visibility) {
            item.visibility = {};
        }
        if (typeof res === 'boolean') {
            item.visibility.edit = bool;
        }
    }

    ensureArrayExists(name: string) {
        if (!this.object[name]) {
            this.object[name] = [];
        }
    }

    addItem(name: string) {
        this.ensureArrayExists(name);
        this.object[name].push({
            __visible: true,
        });
    }

    removeItem(name: string, index: number) {
        this.ensureArrayExists(name);
        (this.object[name] as Record<string, unknown>[]).splice(index, 1);
    }

    toggleAll(field: Field) {
        if (this.object[field.name]?.length === field.options?.length) {
            this.object[field.name] = [];
        } else {
            this.object[field.name] = field.options.map((o) => o.value);
        }
    }

    dispatch(eventName: string) {
        const errors: ConstraintError[] = Object.keys(this.constraintErrors)
            .filter((key) => {
                return !!this.constraintErrors[key];
            })
            .map((key) => {
                return {
                    field: key,
                    message: this.constraintErrors[key],
                };
            });
        this.el.dispatchEvent(createCustomEvent(eventName, {
            object: this.object,
            schema: this.schema,
            context: this.context,
            constraintErrors: errors,
            isValid: !errors.length,
        } as FormEventData));
    }
}
