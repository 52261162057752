import { inject, bindable, LogManager } from 'aurelia-framework';

import factory from '../ol-map/factory/olMapFactory';
import ItemFactory from '../../services/util/ItemFactory';
import Feature from 'ol/Feature';
import Map from 'ol/Map';
import VectorLayer from 'ol/layer/Vector';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';

import { LocationError, LocationResponse, LocationSuccess, LocationFail } from '@wsb_dev/datafi-shared/lib/types/Location';
import { GPSButtonOptions } from '@wsb_dev/datafi-shared/lib/types/Geolocation';

import { GeolocationService } from '../../services/util/Geolocation';
import { AlertService } from '../../services/util/Alert';
import { Geometry } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import { subscribe } from '../../util/decorators/subscribe';
import { IEventMapChange, MapAdaptor } from '../../services/util/MapAdaptor';

const log = LogManager.getLogger('dfp:gps');

@inject(
    factory,
    GeolocationService,
    AlertService,
    MapAdaptor,
)
@subscribe({
    events: [
        {event: 'error', eventEmitter: 'locationService', fn: 'gpsError'},
        {event: 'location', eventEmitter: 'locationService', fn: 'gpsFixed'},
        {event: MapAdaptor.EVENT_MAP_ATTACHED, eventEmitter: 'ma', fn: 'setupMap'},
    ],
})
export class GpsButton {
    map: Map;
    layer: VectorLayer<VectorSource<Geometry>>;

    animationTimer: any;
    gpsClassName: GPSButtonOptions = 'gps_off';
    @bindable active = false;
    zoomActive = false;

    @bindable location: LocationResponse;

    constructor(
        private factory: ItemFactory,
        private locationService: GeolocationService,
        private alerts: AlertService,
        private ma: MapAdaptor,
    ) {}

    detached() {
        this.active = false;
    }
    attached() {
        return;
    }

    async setupMap(e: IEventMapChange) {
        this.map = e.map;

        this.layer = await this.factory.create({
            type: 'ol/layer/Vector',
            id: 'gpsFeatures',
            geometryType: 'point',
            style: {
                type: 'ol/style/Style',
                stroke: {
                    type: 'ol/style/Stroke',
                    color: '#4f4f4f',
                    width: 200,
                },
                fill: {
                    type: 'ol/style/Fill',
                    color: '#ffb600',
                },
                image: {
                    type: 'ol/style/Circle',
                    fill: {
                        type: 'ol/style/Fill',
                        color: '#4285F4',
                    },
                    stroke: {
                        type: 'ol/style/Stroke',
                        color: '#fff',
                    },
                    radius: 8,
                },
            },
            source: {
                type: 'ol/source/Vector',
                features: [],
            },
        });
        this.map.addLayer(this.layer);
    }

    gpsError(errorData: LocationError) {
        this.setAnimation(false);
        if(!this.active){
            return;
        }
        this.gpsClassName = 'error_outline';

        this.alerts.create({
            label: errorData.message || 'An error occurred', //credit: esri
            level: 'error',
            dismissable: true,
        });

        this.locationService.disable();
    }

    gpsFixed(location: LocationResponse) {
        this.setAnimation(false);
        if(!this.active){
            return;
        }
        this.gpsClassName = 'gps_fixed';
        this.updateMapState(location);
    }

    setAnimation(enabled?: boolean) {
        if (!enabled) {
            clearInterval(this.animationTimer);
            this.animationTimer = null;
            return;
        }

        if (this.animationTimer) {
            clearInterval(this.animationTimer);
        }

        let cursor = true;
        this.animationTimer = setInterval(() => {
            if(cursor) {
                cursor = false;
                return this.gpsClassName = 'gps_not_fixed';
            } else {
                cursor = true;
                return this.gpsClassName = 'gps_fixed';
            }
        }, 500);
    }

    activeChanged(newState: boolean) {

        if (newState) {
            this.activateGps();
        } else {
            this.deactivateGps();
        }
    }

    activateGps() {
        this.gpsClassName = 'gps_not_fixed';
        this.setAnimation(true);
        this.locationService.enable();
    }

    deactivateGps() {
        this.setAnimation(false);
        this.gpsClassName = 'gps_off';
        this.updateMapState();
        this.locationService.disable();
        this.zoomActive = false;
    }

    updateMapState(location?: LocationResponse) {
        if (!this.map) {
            return;
        }
        log.debug('Updating map location:', location);
        if (this.active && location) {
            if (this.layer.getSource().getFeatures().length) {
                this.layer.getSource().clear();
            }
            const feature = new Feature({
                geometry: new Point(fromLonLat([location.longitude, location.latitude])),
            });
            this.layer.getSource().addFeature(feature);

            const layerExtent = this.layer.getSource().getExtent();
            if (layerExtent && !this.zoomActive) {
                this.zoomActive = true;
                this.map.getView().animate({
                    center: layerExtent,
                    zoom: 16,
                });
            }
        } else {
            this.layer.getSource().clear();
        }
    }
}
