import { autoinject, bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { EventAggregatorWrapper } from '../../util/events/eventAggregatorWrapper';
import { SimpleStore } from '../../services/util/SimpleStore';
import { StoreManager } from '../../services/util/StoreManager';
import { IDashboardItem, IMapConfig, IMapOptions } from '../../types/Dashboards';
import { AlertService } from '../../services/util/Alert';
import { debounced } from '../../util/decorators/debounced';
import { ILayerConfigMeta, defaults } from '../../components/ol-map-config/ol-map-config';
import { IUrlParams } from '../ol-map/sources/dfpSourceUrlGeojson';
import { AppConfig } from '../../services/util/AppConfig';
import { ActiveProgram } from '../../services/util/ActiveProgram';

@autoinject
export class MapGadgetEditor {
    editItem: IDashboardItem<IMapConfig>;
    @bindable layerDefaults: Record<string, ILayerConfigMeta> = {};
    constructor(
        private stores: StoreManager,
        private ea: EventAggregator,
        private eaw: EventAggregatorWrapper,
        private alerts: AlertService,
        private appConfig: AppConfig,
        private program: ActiveProgram,
    ) {
        this.eaw = eaw;
    }

    async activate(model: IDashboardItem<IMapConfig>): Promise<void> {
        this.editItem = model;
        this.editItem.config.storeIds.forEach((store) => this.refreshStoreData(store));
        await this.program.load();
    }

    updateData(storeId: number) {
        if (!this.editItem.config) {
            this.editItem.config = {};
        }
        if (!this.editItem.config.storeIds) {
            this.editItem.config.storeIds = [];
        }
        if (!this.editItem.config.mapOptions) {
            this.editItem.config.mapOptions = {
                type: 'ol/Map',
                view: { type: 'ol/View' },
                layers: [{
                    id: 'OpenStreetMapDefault',
                    label: 'Basemap',
                    type: 'ol/layer/Tile',
                    source: { type: 'ol/source/OSM' },
                }],
            };
        }
        if (this.editItem.config.storeIds.length) {
            const duplicate = this.editItem.config.storeIds.some((id) => id === storeId);
            if (!duplicate) {
                this.updateLayer(storeId);
                this.editItem.config.storeIds.push(storeId);
            }
        } else {
            this.updateLayer(storeId);
            this.editItem.config.storeIds.push(storeId);
        }
    }

    async updateLayer(storeId: number) {
        const duplicate = this.editItem.config.mapOptions.layers.some((layer) => Number(layer.id) === storeId);
        const result = await this.refreshStoreData(storeId) as SimpleStore;
        if (!duplicate && result.geometryType) {
            this.editItem.config.mapOptions.layers.push(
                {
                    type: 'ol/layer/VectorImage',
                    id: `store-${storeId}`,
                    label: result.name,
                    source: {
                        type: 'dfp/source/VectorDFP',
                        url: {
                            type: 'url/dfp/GeoJSON',
                            baseUrl: this.appConfig?.API_HOST,
                            typeId: result.id,
                            serviceType: result.service.path === 'api/v1/form-submissions' ? 'form-submissions' : 'projects',
                            query: result.query,
                            programId: this.program.id,
                        } as IUrlParams,
                    },
                    geometryType: result.geometryType,
                    style: JSON.parse(JSON.stringify(defaults['ol/style/Style'])),
                },
            );
            this.ea.publish('edit-item', this.editItem);
        } else {
            this.alerts.create({ label: `${result.name} could not be added to map. Check the configuration`, dismissable: true });
        }
        return result;
    }

    @debounced(200)
    mapConfigChanged(config?: IMapOptions): Promise<void> {

        // if this is the map config change event - then the config gets passed from $event.detail in template
        // then we need to update the configs store ID's because layers have been added or removed
        if (config?.layers) {
            config.layers.forEach((layer) => {
                const filterId = parseInt(layer.id.split('-')[1]);
                if (filterId) {
                    this.editItem.config.storeIds = this.editItem.config.storeIds.filter((id) => id === filterId);
                }
                if (this.editItem.config.storeIds.length === 1 && !filterId) {
                    return this.editItem.config.storeIds = [];
                }
            });
        }
        this.ea.publish('edit-item', this.editItem);
        return Promise.resolve();
    }

    async refreshStoreData(store) {
        const storeData = this.stores.getStore(store) as SimpleStore;

        const result = await storeData.refresh().catch((e) => {
            this.alerts.create({ label: 'Layer could not be added to map. Check the configuration', dismissable: true });
        });
        this.refreshDefaults(result);
        return result;
    }

    refreshDefaults(layer) {
        const fieldSelect = layer.fields.filter((field) => field.options.length && field.visibility.list);
        this.layerDefaults[`store-${layer.id}`] = {
            config: {
                id: `store-${layer.id}`,
                label: layer.name,
                type: 'ol/layer/VectorImage',
                geometryType: layer.geometryType,
                visible: true,
                source: {
                    type: 'dfp/source/VectorDFP',
                    url: {
                        type: 'url/dfp/GeoJSON',
                        baseUrl: this.appConfig.API_HOST,
                        typeId: layer.id,
                        serviceType: layer.service.path === 'api/v1/form-submissions' ? 'form-submissions' : 'projects',
                        query: {
                            $modify: { toGeoJSON: [] },
                        },
                        programId: this.program.id,
                    } as IUrlParams,
                },
            },
            fields: fieldSelect,
        } as ILayerConfigMeta;

    }
}
