import { bindable, inject } from 'aurelia-framework';
import { APIFile } from '@wsb_dev/datafi-shared/lib/types/File';
import { createCustomEvent } from '../../util/events/createCustomEvent';

@inject(
    Element,
)
export class MdcFileList {
    @bindable files: APIFile[];

    constructor(private element: Element) { }

    remove(file: APIFile) {
        const i = this.files.indexOf(file);
        if (i > -1) {
            this.files.splice(i, 1);
        }

        return file;
    }

    dispatchEvent(type: string, file: APIFile) {
        this.element.dispatchEvent(createCustomEvent<APIFile>(type, file, true));
        if (typeof this[type] === 'function') {
            return this[type](file);
        }
    }
}
