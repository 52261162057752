import { autoinject, bindable } from 'aurelia-framework';
import { subscribe } from '../../util/decorators/subscribe';
import { MapAdaptor, SelectItem, SelectResult } from '../../services/util/MapAdaptor';
import { flatten } from '../../components/admin/fields/flatten';
import { DatafiProAPI } from '../../services/api/DatafiProAPI';
import { FormSubmission } from '@wsb_dev/datafi-shared/lib/types/FormSubmission';
import { ActiveProgram } from '../../services/util/ActiveProgram';
import { SelectionChange } from '../../components/mdc-file-gallery/events/SelectionChange';
import { EventAggregatorWrapper } from '../../util/events/eventAggregatorWrapper';
import { flattenFields } from '@wsb_dev/datafi-shared/lib/util/surveys/flattenFields';

@subscribe({
    events: [
        { eventEmitter: 'eaw', event: SelectionChange, fn: 'handleSelectionChanged' },
        { event: MapAdaptor.EVENT_SELECT, eventEmitter: 'mapAdaptor', fn: 'handleSelectionChanged' },
        { event: MapAdaptor.EVENT_DESELECT, eventEmitter: 'mapAdaptor', fn: 'handleSelectionChanged' },
    ],
})
@autoinject
export class OlMapSelection {
    mapSelection: Record<string, any>[];
    @bindable mapAdaptor: MapAdaptor;

    constructor(
        private api: DatafiProAPI,
        private program: ActiveProgram,
        private eaw: EventAggregatorWrapper,
    ) { }

    attached() {
        // remove existing selections on initialization
        // this is because you can't select features that are already selected
        this.mapAdaptor?.clearSelection();
    }

    async handleSelectionChanged(event: SelectResult) {
        if (event.type === 'select') {
            const props = [];
            const query = {
                program_id: this.program.id,
                $select: ['metadata', 'createdAt', 'createdBy', 'updatedAt', 'updatedBy'],
            };
            if (event?.target[0]?.layerId) { // map was clicked

                if (event.target[0].layerId.startsWith('store-')) {
                    this.mapSelection = event.target.map((i) => {
                        if (i.properties) {
                            delete i.properties?.metadata?.geometry;
                            delete i.properties?.metadata?.geom;
                            delete i.properties?.geometry;
                            delete i.properties?.geom;
                            return flatten(i.properties);
                        } else {
                            return i;
                        }
                    });
                    return;
                }

                event.target.forEach((selection) => {
                    switch (selection.layerId) {
                    case 'projectFeatures':
                        this.api.projects.find({
                            query: {
                                id: selection.properties.id,
                                program_id: this.program.id,
                                $select: ['status', 'project_name', 'created_by', 'metadata'],
                            },
                        }).then((project: Record<string, any>) => {
                            project.data.forEach((p) => {
                                delete p.geom;
                                delete p.geometry;
                                props.push(flatten(p));
                            });
                        });
                        break;
                    case 'gpsFeatures':
                        props.push({ GPS: 'Current location' });
                        break;
                    case 'userFeatures':
                        props.push({
                            name: selection.properties.user?.fullname,
                            email: selection.properties.user?.email,
                            date: selection.properties.updatedAt,
                        });
                        break;
                    case 'photoFeatures':
                        if (selection['properties']) {
                            delete selection['properties']?.geom;
                            delete selection['properties']?.geometry;
                            props.push(this.flattenObj(selection['properties']));
                        }
                        break;
                    default:
                        // Datafi Pro Survey
                        if (selection.layerId.startsWith('surveys')) {
                            if (selection.properties?.features) { // Clustered surveys
                                selection.properties.features.forEach((att) => {
                                    this.api.formSubmissions.get(att.getProperties().id, query).then((survey: FormSubmission) => {
                                        this.setMapSelectionFields(survey);
                                    });
                                });
                            } else {
                                this.api.formSubmissions.get(selection.properties.id, query).then((survey: FormSubmission) => {
                                    this.setMapSelectionFields(survey);
                                });
                            }
                        }

                        // assets
                        else if (selection.layerId.startsWith('assets')) {
                            this.api.programAssets.get(selection.properties.id, query)
                                .then((res) => {
                                    this.mapSelection.push(this.flattenObj(res));
                                });
                        }
                        break;
                    }
                    this.mapSelection = props;
                });
            }
        }
        else { // file list clicked
            this.mapSelection = event.target;
        }
        if (event.type === 'deselect') {
            this.mapSelection = undefined;
        }
    }

    setMapSelectionFields(survey: FormSubmission): void {
        const schema = flattenFields(this.program.surveys.find((s) => s.id === survey.survey_id).surveySchema);
        const surveyFields = this.flattenObj(survey);

        const visibleFields = {};
        schema.forEach((field) => {
            if (field.visibility.list) { visibleFields[field.label] = surveyFields[field.name]; }
        });
        this.mapSelection.push(visibleFields);
    }

    flattenObj = (obj = {}) => Object.keys(obj || {}).reduce((acc, cur) => {
        if (typeof obj[cur] === 'object') {
            acc = { ...acc, ...this.flattenObj(obj[cur]) };
        } else { acc[cur] = obj[cur]; }
        return acc;
    }, {})
}
