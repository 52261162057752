import { LogManager, bindable, bindingMode } from 'aurelia-framework';

export interface ILine {
    number: number;
    error: boolean;
}

const log = LogManager.getLogger('dfp:json-field');

export class MdcJsonField {
    @bindable textValue: string;
    @bindable({defaultBindingMode: bindingMode.twoWay }) value: Record<string, any>;
    error: string;
    errorLine: number;
    count: number;
    textField: HTMLElement;
    height: number;
    scroll: number;
    spaces = 4;

    lines: ILine[];

    attached() {
        this.height = this.textField.offsetHeight;
    }

    formatText(){
        try {
            this.textValue = JSON.stringify(JSON.parse(this.textValue), null, this.spaces || 4);
        } catch(e){
            log.error(e);
        }
    }
    resetText(){
        this.textValue = JSON.stringify(this.value, null, this.spaces || 4);
    }

    valueChanged() {
        try {
            const newValue = JSON.stringify(this.value);
            const text = this.textValue ?
                JSON.stringify(JSON.parse(this.textValue)) :
                null;
            if (newValue !== text) {
                this.textValue = JSON.stringify(this.value, null, this.spaces || 4);
            }
        } catch (e) {
            log.error(e);
        }
    }

    textValueChanged(text: string) {
        this.error = null;
        let errorLine = 0;
        try {
            this.value = JSON.parse(text);
        } catch (e) {
            this.error = e.message;
            try {
                errorLine = parseInt(/at line (\d+)/.exec(e.message)[1], 10);
            } catch(e){
                log.debug(e);
            }
        }
        const count = text.split('\n').length;
        const lines: ILine[] = [];
        for (let i = 1; i < count + 1; i++) {
            lines.push({
                number: i,
                error: i === errorLine,
            });
        }
        this.lines = lines;
    }

}
