import { bindable, computedFrom } from 'aurelia-framework';

export const FIRST_PAGE = 1;

export class MdcPagination {
    @bindable page = 1;
    @bindable total: number;
    @bindable perPage = 10;
    @bindable perPageOptions = [
        10,
        25,
        50,
        100,
    ]

    perPageChanged(perPage){
        if(typeof perPage === 'string'){
            this.perPage = parseInt(perPage, 10);
            return;
        }
        if(this.page > this.lastPage){
            this.page = this.lastPage;
        }
    }

    @computedFrom('total', 'perPage')
    get lastPage() : number {
        return this.total < this.perPage ? FIRST_PAGE : Math.ceil(this.total / this.perPage);
    }

    @computedFrom('page', 'perPage', 'total')
    get fromCount() : number {
        return (this.page - 1) * this.perPage + 1;
    }

    @computedFrom('page', 'perPage', 'total')
    get toCount() : number {
        const endCount = this.perPage * this.page;
        if(endCount > this.total){
            return this.total;
        }
        return this.page * this.perPage;
    }

    gotoNext() {
        if(this.page < this.lastPage){
            this.page ++;
        }
    }

    gotoPrevious(){
        if(this.page > FIRST_PAGE){
            this.page --;
        }
    }

    gotoFirst(){
        this.page = FIRST_PAGE;
    }

    gotoLast(){
        this.page = this.lastPage;
    }
}
