import { Field } from '@wsb_dev/datafi-shared/lib/types';
import { bindable, autoinject, bindingMode } from 'aurelia-framework';
import { ColorMap, ValueStyle } from '../../components/ol-map/styles/attributeColorMap';
import omit from 'lodash.omit';
import Map from 'ol/Map';
import { createCustomEvent } from '../../util/events/createCustomEvent';

export type TGeometryType = 'point' | 'polyline' | 'polygon';
export type TInsertAt = 'bottom' | 'top';
export interface LayerConfig {
    id: string;
    visible?: boolean;
    label?: string,
    type?: string;
    source?: any;
    style?: any;
    geometryType?: TGeometryType;
    /**
     * Flag to prevent map adaptor from converting this layer to a DFP form
     */
    dfpReady?: boolean;
    /**
     * Display in basemap picker
     */
    basemap?: boolean;
    thumbnailUrl?: string;
    attributions?: string;
}

export interface ILayerConfigMeta {
    fields?: Field[];
    config: LayerConfig;
    /**
     * where the layer gets inserted at in the map
     */
    insertAt?: TInsertAt;
}

export interface MapConfig {
    type: string;
    view: {
        type: string,
        center?: number[],
        zoom?: number
    }
    layers: LayerConfig[];
}

export const defaults = {
    'ol/style/Style': {
        type: 'ol/style/Style',
        stroke: {
            type: 'ol/style/Stroke',
            color: '#4f4f4f',
            width: 2,
        },
        fill: {
            type: 'ol/style/Fill',
            color: '#ffb600',
        },
        image: {
            type: 'ol/style/Circle',
            fill: {
                type: 'ol/style/Fill',
                color: '#cc66ff',
            },
            stroke: {
                type: 'ol/style/Stroke',
                color: '#fff',
            },
            radius: 8,
        },
    },
    'style/colorMap': {
        type: 'style/colorMap',
        field: 'Field name',
        styles: [],
    },
    'style/clusterStyle': {
        type: 'style/clusterStyle',
        color: '#ffb600',
        outlineColor: '#4f4f4f',
        outlineWidth: 2,
    },
};

@autoinject
export class OlMapConfig {
    @bindable mapOff: boolean;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @bindable mapConfig: MapConfig;
    @bindable selectedLayer: LayerConfig;
    @bindable program;
    /**
     * maps layer ID to layer name for a list of available layer IDs in the map.
     * This is used to populate the layer selector.
     *
     * @type {Record<string, string>}
     */
    @bindable layerDefaults: Record<string, ILayerConfigMeta>;
    layerDefaultsArray: ILayerConfigMeta[];
    layerDefaultsChanged(defaults) {
        this.layerDefaultsArray = defaults ?
            Object.values(defaults) :
            [];
    }

    defaultMap: MapConfig = {
        type: 'ol/Map',
        view: { type: 'ol/View' },
        layers: [{
            id: 'OpenStreetMapDefault',
            label: 'OSM Default',
            type: 'ol/layer/Tile',
            source: { type: 'ol/source/OSM' },
        }],
    }

    constructor(private element: Element) {}

    attached() {
        if (this.mapConfig) {
            if (this.mapConfig.view.center && this.mapConfig.view.zoom) {
                this.defaultMap.view = {
                    type: 'ol/View',
                    center: this.mapConfig.view.center,
                    zoom: this.mapConfig.view.zoom,
                };
            }

            if (this.mapConfig.layers && this.layerDefaults) {
                this.mapConfig.layers.forEach((layer) => {
                    const defaults = this.layerDefaults[layer.id];
                    Object.assign(layer, {
                        ...defaults?.config,
                        ...layer,
                    });
                });
            }
        }
    }

    updateExtent(map: Map) {
        this.mapConfig.view = {
            type: 'ol/View',
            center: map.getView().getCenter(),
            zoom: Math.round(map.getView().getZoom()),
        };
    }

    updateField(layer: LayerConfig) {
        if (layer.style.field) {
            const config = this.layerDefaults[layer.id];
            if (config && config.fields) {
                const field = config.fields.find((field) => layer.style.field === field.path);
                if (field?.options) {
                    layer.style.styles = [];
                    this.addColorMapValue('default', layer.style);
                    field.options.forEach((o) => {
                        this.addColorMapValue(o.value, layer.style);
                    });
                }
            }

            if (layer.source.type === 'dfp/source/VectorDFP') {
                if (!layer.source.url) {
                    layer.source.url = {};
                }

                if (!layer.source.url.query) {
                    layer.source.url.query = {};
                }

                if (!layer.source.url.query.$select) {
                    layer.source.url.query.$select = [
                        'id',
                        'geom',
                    ];
                }

                // push the base field part into the select query
                const baseField = layer.style.field.split('.')[0];

                if (layer.source.url.query.$select.indexOf(baseField) < 0) {
                    layer.source.url.query.$select.push(baseField);
                }
            }
        }
    }

    updateConfig(selectedLayer) {
        const type = selectedLayer.style.type;
        if (!selectedLayer.id) {
            return;
        }
        for (const layer in this.mapConfig.layers) {
            if (this.mapConfig.layers[layer].id === selectedLayer.id) {
                this.mapConfig.layers[layer] = selectedLayer;
            }
        }
        if (!type) {
            return;
        }
        selectedLayer.style = JSON.parse(JSON.stringify(defaults[type]));
        if (type === 'style/clusterStyle') {
            selectedLayer.source = { type: 'ol/source/Cluster', source: selectedLayer.source };
        } else if (selectedLayer.source.source) {
            selectedLayer.source = selectedLayer.source.source;
        }
    }

    addColorMapValue(fieldValue: string, colorMap: ColorMap) {

        if (!colorMap.styles) {
            colorMap.styles = [];
        }
        (colorMap.styles as ValueStyle[]).push({
            value: fieldValue,
            ...JSON.parse(JSON.stringify(
                omit(defaults['ol/style/Style'], ['type']),
            )),
        });
    }

    removeColorMapValue(index: number, colorMap: ColorMap) {
        colorMap.styles.splice(index, 1);
    }

    addLayer(config: ILayerConfigMeta) {
        this.mapConfig.layers.push({
            dfpReady: true,
            type: 'ol/layer/VectorImage',
            visible: false,
            source: {
                type: 'ol/source/Vector',
                features: [],
            },
            style: JSON.parse(JSON.stringify(defaults['ol/style/Style'])),
            ...config.config,
        });

        this.selectedLayer = this.mapConfig.layers[this.mapConfig.layers.length - 1];
    }

    removeLayer(index: number) {
        this.mapConfig.layers.splice(index, 1);
        this.element.dispatchEvent(createCustomEvent('change', this.mapConfig, true));
    }
}
