import { bindable } from 'aurelia-framework';
import Map from 'ol/Map';
import { subscribe } from '../../util/decorators/subscribe';
import VectorLayer from 'ol/layer/Vector';
import { ColorMapFunction } from '../../components/ol-map/styles/attributeColorMap';
import { IStyleFunction } from '../../components/ol-map/styles/styleTypes';
import { Style } from 'ol/style';
import { ClusterFunction } from '../../components/ol-map/styles/clusterStyle';
import { Collection } from 'ol';
import BaseLayer from 'ol/layer/Base';
import TileLayer from 'ol/layer/Tile';
import { titleize } from '@wsb_dev/datafi-shared/lib/util/strings/titleize';

export interface IStyleProps {
    'background-color'?: string;
    'border-color'?: string;
    'border-radius'?: string;
    'background-image'?: string;
    'background-size'?: 'contain';
    height?: string;
    width?: string;
}
export interface ILayerLegendStyle {
    label: string;
    styles?: ILayerLegendStyle[];
    style?: IStyleProps;
}
export type TStyleType = Style | Record<string|number, any>;

@subscribe({
    events: [
        { eventEmitter: 'layers', event: 'change:length', fn: 'onLayersChanged' },
    ],
    attached: 'layersAttached',
    detached: 'detached',

    on: 'on',
    off: 'un',
})

export class OlMapLegend {

    @bindable map: Map;
    mapChanged(){
        if(!this.map){
            return;
        }
        this.layers = this.map.getLayers();
        this.updateLegendLayerStyles();
        this.layersAttached();
    }
    layers: Collection<BaseLayer>;

    layerStyles: ILayerLegendStyle[];

    attached(){
        this.mapChanged();
    }
    // this is a placeholder function that gets populated with
    // the subscribe to events function
    layersAttached(): void {
        return;
    }

    onLayersChanged = () => {
        this.updateLegendLayerStyles();
    }

    updateLegendLayerStyles() {
        this.layerStyles = this.map.getLayers().getArray()
            .filter((layer) => !(layer instanceof TileLayer))
            .map((layer: VectorLayer<any>) => {
                const layerStyle = layer.getStyle() as Style|IStyleFunction;
                const label = layer.get('label') ||  titleize( layer.get('id') );
                const geom = layer.get('geometryType');

                if (layerStyle.hasOwnProperty('dfpStyleType')) {
                    const layerFunction = layerStyle as IStyleFunction;

                    // check for custom styling (cluster & multiple attributes)
                    if (layerFunction.dfpStyleType === 'attribute') {
                        return this.getAttributeStyle(layerStyle as ColorMapFunction, label, geom);
                    }
                    else if (layerFunction.dfpStyleType === 'cluster') { // cluster
                        return this.getClusterStyle(layerStyle as ClusterFunction, label);
                    }

                    // skip thumbnails for now
                    else if(layerFunction.dfpStyleType === 'thumbnail'){
                        return;
                    }

                } else if(geom === 'polygon'){
                    return this.getPolygonStyle(layerStyle as Style, label);
                }
                // Single style and Point
                return this.getPointStyle(layerStyle as Style, label);
            })
            //filter out empty items
            .filter((item) => !!item);
    }

    getPointStyle(layerStyle: Style, title: string): ILayerLegendStyle {

        const image = layerStyle.getImage() as any;
        if(image.getSrc){
            const src = image.getSrc();
            const width = image.getWidth() || 20;
            const height = image.getHeight() || 20;

            return {
                label: title,
                style: {
                    'background-image': `url("${src}")`,
                    'background-size': 'contain',
                    'border-color': '#fff',
                    height: `${height}px`,
                    width: `${width}px`,
                },
            };
        }
        const pointRadius: number =  image.getRadius() * 2;
        const backgroundColor: string =  image.getFill().getColor();
        const borderColor: string =  image.getStroke().getColor();

        const style: ILayerLegendStyle = {
            label: title,
            style: {
                'background-color': backgroundColor,
                'border-color': borderColor,
                'border-radius': '50%',
                ...(pointRadius > 0 && { height: `${pointRadius}px`, width: `${pointRadius}px` }),
            },
        };

        return style;

    }

    getPolygonStyle(layerStyle: Style, label: string): ILayerLegendStyle {
        const backgroundColor = layerStyle?.getFill()?.getColor() as string;
        const borderColor = layerStyle?.getStroke()?.getColor() as string;

        const style: ILayerLegendStyle = {
            label: label,
            style: {
                'background-color': backgroundColor,
                'border-color': borderColor,
            },
        };

        return style;
    }

    getClusterStyle(layerStyle: ClusterFunction, label: string): ILayerLegendStyle {
        const backgroundColor = layerStyle.options.color;
        const borderColor = layerStyle.options.outlineColor as string;

        const style = {
            label: label,
            style: {
                'background-color': backgroundColor,
                'border-color': borderColor,
            },
        };

        return style;
    }

    getAttributeStyle(layerStyle: ColorMapFunction, label: string, geom: string): ILayerLegendStyle {
        if(!layerStyle.colorMap?.styles){
            return {label};
        }

        if(!Array.isArray(layerStyle.colorMap.styles)){
            layerStyle.colorMap.styles = Object.keys(layerStyle.colorMap.styles).map((key) => {
                return {
                    value: key,
                    ...layerStyle.colorMap.styles[key],
                };
            });
        }

        // multiple attributes
        const styles = layerStyle.colorMap.styles.map((style) => {

            const fieldLabel = `${label}: ${layerStyle.colorMap.field}`;
            const valueStyle = layerStyle.cache.get(style.value);
            if(!valueStyle){
                return;
            }

            if (geom === 'point') { // Point
                return this.getPointStyle(valueStyle, titleize(style.value as string));
            } else { // Polygon
                return this.getPolygonStyle(valueStyle, titleize(style.value as string));
            }
        });
        return {
            label,
            styles,
        };
    }
}
