
import OlMap from 'ol/Map';
import View from 'ol/View';

import TileLayer from 'ol/layer/Tile';
import ImageLayer from 'ol/layer/Image';
import VectorLayer from 'ol/layer/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import Heatmap from 'ol/layer/Heatmap';

// import MapboxVector from 'ol/layer/MapboxVector';
import WebGLPointsLayer from 'ol/layer/WebGLPoints';

import OSM from 'ol/source/OSM';
import XYZ from 'ol/source/XYZ';
import ImageWMS from 'ol/source/ImageWMS';
import TileWMS from 'ol/source/TileWMS';
import VectorSource from 'ol/source/Vector';

import GeoJSON from 'ol/format/GeoJSON';

import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Circle from 'ol/style/Circle';
import Text from 'ol/style/Text';
import Icon from 'ol/style/Icon';

import Select from 'ol/interaction/Select';
import Modify from 'ol/interaction/Modify';
import Draw from 'ol/interaction/Draw';
import Snap from 'ol/interaction/Snap';
import Cluster from 'ol/source/Cluster';

import ScaleLine from 'ol/control/ScaleLine';

import {bbox, tile} from 'ol/loadingstrategy';

import ItemFactory from '../../../services/util/ItemFactory';

import { createClusterStyle } from '../styles/clusterStyle';
import { createAttributeColorMap } from '../styles/attributeColorMap';
import { createThumbnailStyle } from '../styles/thumbnailStyle';
import { setWithCredentials } from 'ol/featureloader';
import { getDfpGeojsonUrlObject } from '../sources/dfpSourceUrlGeojson';
import {createXYZ} from 'ol/tilegrid';
import {defaults as defaultControls} from 'ol/control/defaults';

setWithCredentials(true);

const defaults = {
    'ol/View'() {
        return {
            center: [0, 0],
            zoom: 2,
        };
    },
    'ol/Map'() {
        return {
            view: { type: 'ol/View' },
            layers: [{
                type: 'ol/layer/Tile',
                source: { type: 'ol/source/OSM' },
            }],
            controls: defaultControls({attributionOptions: {collapsible: false}}),
        };
    },
    'dfp/source/VectorDFP'() {
        return {
            type: 'ol/source/VectorImage',
            strategy: {
                type: 'ol/strategy/tile',
                tileGrid: {
                    type: 'ol/tilegrid/TileGrid',
                    tileSize: 512,
                },
            },
            format: {
                type: 'ol/format/GeoJSON',
            },
            url: {
                type: 'url/dfp/GeoJSON',
            },
        };
    },
};

const items = {
    // openlayers
    'ol/Map': OlMap,
    'ol/View': View,

    // layers
    'ol/layer/Heatmap': Heatmap,
    'ol/layer/Tile': TileLayer,
    'ol/layer/Image': ImageLayer,
    'ol/layer/Vector': VectorLayer,
    'ol/layer/VectorImage': VectorImageLayer,
    'ol/layer/WebGLPointsLayer': WebGLPointsLayer,
    //   'ol/layer/MapboxVector': MapboxVector,

    // sources
    'ol/source/Vector': VectorSource,
    'ol/source/XYZ': XYZ,
    'ol/source/OSM': OSM,
    'ol/source/ImageWMS': ImageWMS,
    'ol/source/TileWMS': TileWMS,
    'ol/source/Cluster': Cluster,

    // formats
    'ol/format/GeoJSON': GeoJSON,

    // styles
    'ol/style/Style': Style,
    'ol/style/Circle': Circle,
    'ol/style/Fill': Fill,
    'ol/style/Icon': Icon,
    'ol/style/Stroke': Stroke,
    'ol/style/Text': Text,

    // interactions
    'ol/interaction/Select': Select,
    'ol/interaction/Modify': Modify,
    'ol/interaction/Draw': Draw,
    'ol/interaction/Snap': Snap,

    // controls
    'ol/control/ScaleLine': ScaleLine,

    // stragies
    'ol/strategy/bbox': function get(){ return bbox;},
    'ol/strategy/tile': function(opt){
        const tileGrid = createXYZ(opt.tileGrid);
        return tile(tileGrid);
    },

    // custom layers/sources
    'dfp/source/VectorDFP': VectorSource,
    'url/dfp/GeoJSON': getDfpGeojsonUrlObject,

    // custom styles
    'style/photoStyle': createClusterStyle,
    'style/clusterStyle': createClusterStyle,
    'style/colorMap': createAttributeColorMap,
    'style/thumbnailStyle': createThumbnailStyle,

};

export const factory = new ItemFactory(items, defaults);

export default factory;
