import { bindable, autoinject, computedFrom } from 'aurelia-framework';
import { SimpleStore } from '../../services/util/SimpleStore';
import { StoreManager } from '../../services/util/StoreManager';
import { subscribe } from '../../util/decorators/subscribe';
import { EventAggregatorWrapper } from '../../util/events/eventAggregatorWrapper';
import { AlertService } from '../../services/util/Alert';
import { Map } from 'ol';
import { MapAdaptor } from '../../services/util/MapAdaptor';
import { IDashboardItem, IMapConfig } from '../../types/Dashboards';
import { getterThrottle } from '../../util/decorators/getterThrottle';

@autoinject
@subscribe({
    events: [{ eventEmitter: 'storeData', event: SimpleStore.change, fn: 'getStoreData' }],
    attached: 'storeAttached',
    detached: 'storeDetached',
})

@subscribe({
    events: [
        { eventEmitter: 'eaw', event: 'edit-item', fn: 'getStoreData' },
    ],
})

export class MapGadget {
    @bindable mapDashboardOptions: IDashboardItem<IMapConfig>;

    @computedFrom('mapDashboardOptions')
    @getterThrottle(100)
    get mapOptions() {
        return { ...this.mapDashboardOptions.config.mapOptions };
    }

    @bindable editEnabled: boolean;
    @bindable mapSelection: Record<string, any>[];
    @bindable map: Map;
    @bindable storeData: SimpleStore[];
    private ma: MapAdaptor;

    constructor(
        private stores: StoreManager,
        private eaw: EventAggregatorWrapper,
        private alerts: AlertService,
    ) {
        this.ma = new MapAdaptor;
        this.eaw = eaw;
    }

    async setupMap(map: Map): Promise<void> {
        this.map = map;
        this.ma.map = this.map;

        if (!this.mapDashboardOptions.config.mapOptions) {
            this.updateExtent();
        }
        this.getStoreData();
    }

    unbind() {
        this.eaw.off('edit-item', 'getStoreData');
    }

    async activate(model) {
        this.editEnabled = model.editEnabled;
        this.mapDashboardOptions = model.options as IDashboardItem<IMapConfig>;
    }
    storeAttached() {
        // Called and overriden in subscribe function
    }

    storeDetached() {
        // Called and overriden in subscribe function
    }

    updateExtent() {
        this.mapDashboardOptions.config.mapOptions.view.center = this.map.getView().getCenter();
        this.mapDashboardOptions.config.mapOptions.view.zoom = this.map.getView().getZoom();
    }

    mapChanged() {
        if (this.map) {
            this.setupMap(this.map);
        }
    }

    getStoreData(editItem?: IDashboardItem<IMapConfig>) {

        if (editItem?.config?.mapOptions) {
            if (editItem.id !== this.mapDashboardOptions.id) {
                return;
            }
            this.mapDashboardOptions = { ...editItem };
        }

        if (this.storeData) {
            this.storeDetached();
        }

        if (this.mapDashboardOptions.config?.storeIds?.length) {
            this.storeData = this.mapDashboardOptions.config.storeIds.map((id) => {
                return this.stores.getStore(id) as SimpleStore;
            }).filter(((store) => !!store));

            const promises = this.storeData.map((store) => {
                return store.refresh();
            });

            Promise.all(promises).then((result) => {
                result.forEach((r) => {
                    this.ma.updateLayer({
                        layerId: `store-${r.id}`,
                        create: r.data,
                        createLayer: true,
                    });
                });
            }).catch((e) => {
                this.alerts.create({
                    label: 'Data not found',
                    level: 'error',
                    dismissable: true,
                });
            });
            this.storeAttached();
        }
    }
}
