import { autoinject, bindable } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { APIFile } from '@wsb_dev/datafi-shared/lib/types/File';
import { FileFolder } from '@wsb_dev/datafi-shared/lib/types/FileFolder';
import { AuthenticationService } from '../../services/api/authentication';

export interface IUploadActivateParams {
    folders: FileFolder[];
    getFileName: (file: File) => string;
}

@autoinject
export class MdcUploadDialog {
    @bindable files: APIFile[] = [];
    @bindable pending: boolean;

    @bindable tags: string[];
    folders: FileFolder[];
    @bindable selectedFolder: string;
    @bindable getFileName: (file: File) => string;

    constructor(
        private controller: DialogController,
        private auth: AuthenticationService,
    ) { }

    activate(model: IUploadActivateParams): void {
        this.tags = [`${this.auth.me.username}`];
        this.folders = model.folders;
        this.getFileName = model.getFileName;
    }
}
